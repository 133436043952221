<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              สร้างกลุ่มพนักงาน
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.employee_type_name"
                      label="ชื่อกลุ่ม"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.employee_type_comment"
                      label="คำอธิบาย"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_report from '../../../services/report'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'เลขใบสั่งซื้อ', value: 'updated_at' },
      { text: 'วันที่สั่งซื้อ', value: 'order_inv' },
      { text: 'จำนวนรายการ', value: 'employee_type_comment' },
      { text: 'จำนวน(ชิ้น)', value: 'credit_product_amt' },
      { text: 'ค่าสินค้า', value: 'amount_credit' },
      { text: 'ต้นทุน', value: 'remain' },
      { text: 'ค่าส่ง', value: 'member_shop_name' },
      { text: 'ส่วนลด', value: 'member_shop_name' },
      { text: 'รวมสุทธิ', value: 'member_shop_name' },
      { text: 'ล่าสุด', value: 'member_shop_name' },
      { text: 'สถานะ', value: 'member_shop_name' },
      // { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getReport()
  },

  methods: {

    editItem(item) {
      this.editedIndex = this.employees_types.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.employees_types.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_report.employeeTypeDestroy(this.editedItem).then(res => {

        this.desserts.splice(this.editedIndex, 1)
      }).catch(err => {

      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      console.log(this.editedIndex,444)
      if (this.editedIndex > -1) {
        instance_report.employeeTypeUpdate(this.editedItem)
          .then(res => {
            Object.assign(this.employees_types[this.editedIndex], this.editedItem)
          }).catch(err => {

        });
      } else {
        instance_report.employeeTypeStore(this.editedItem)
          .then(res => {
            this.employees_types.push(this.editedItem)
          }).catch(err => {

        });
      }
      this.close()
    },
    getReport() {
      var data = {
        search : '',
        date : '',
        status : '',
      }

      instance_report.getReportManageSell(data).then(res => {
        this.report = res.data.data;
      }).catch(err => {

      });
    },
    getUser() {
      // eslint-disable-next-line no-useless-concat
      const baseURL = `${process.env.VUE_APP_BASEURL}` + '/login'
      instance
        .get(`${baseURL}user?search=`)
        .then(res => {
          if (res.status === 200) {
            this.users = res.data.data
          }

          // Unauthorized Logout
          // eslint-disable-next-line no-empty
          if (res.status === 200 && res.data.error === 'Unauthorized') {
            localStorage.removeItem('token_authentication')
          }
        })
        .catch(error => {
          console.log(error)

        })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
